// Copyright 1999-2024. WebPros International GmbH. All rights reserved.
import { redirect } from 'react-router-dom';
import apolloClient from 'apolloClient';
import { readGraphQlListInputFromStorage } from 'components/common/GraphQlList';
import { Locale } from 'jsw';
import VirtualDirectorySidebar from 'components/sidebars/VirtualDirectorySidebar';
import { protectedDirectoryUserAddRoute } from 'routes/protectedDirectoryUserAddRoute';
import { protectedDirectoryUserPasswordRoute } from 'routes/protectedDirectoryUserPasswordRoute';
import { protectedDirectoryEditRoute } from 'routes/protectedDirectoryEditRoute';

import VIRTUAL_DIRECTORY_PAGE from 'queries/VirtualDirectoryPage.graphql';
import VIRTUAL_DIRECTORY_BROWSE_PAGE from 'queries/VirtualDirectoryBrowsePage.graphql';
import VIRTUAL_DIRECTORY_PROTECTION_PAGE from 'queries/VirtualDirectoryProtectionPage.graphql';
import VIRTUAL_DIRECTORY_PROTECTION_USER_LIST from 'queries/VirtualDirectoryProtectionUserList.graphql';
import VIRTUAL_DIRECTORY_MIME_TYPE_LIST from 'queries/VirtualDirectoryMimeTypeList.graphql';
import VIRTUAL_DIRECTORY_CONTENT_LIST from 'queries/VirtualDirectoryContentList.graphql';
import ASP_DOT_NET_DOMAIN_SETTINGS_PAGE from 'queries/AspDotNetDomainSettingsPage.graphql';
import VIRTUAL_DIRECTORY_PHP_PAGE from 'queries/VirtualDirectoryPhpPage.graphql';
import IIS_MANAGER_MIME_CONTENT_TYPES from 'queries/IisManagerMimeContentTypes.graphql';
import VIRTUAL_DIRECTORY_EDIT_MIME_TYPE_PAGE from 'queries/VirtualDirectoryEditMimeTypePage.graphql';

const virtualDirectorySettingRoutes = [
    {
        path: 'asp-dot-net',
        componentLoader: () => import(
            /* webpackChunkName: "components/pages/VirtualDirectoryPage/AspDotNetSettingsPage" */
            'components/pages/VirtualDirectoryPage/AspDotNetSettingsPage'
        ),
        loader: async ({ request: { url }, params: { domainId } }) => {
            const path = new URL(url).searchParams.get('path') || '/';
            const { data } = await apolloClient.query({
                query: ASP_DOT_NET_DOMAIN_SETTINGS_PAGE,
                variables: { id: btoa(`Domain:${domainId}`), webPath: path },
            });

            if (!data.domain.webScriptingSettings?.aspDotNet || !data.domain.aspNetSettings?.configs.length) {
                throw new Response('Not Found', { status: 404 });
            }
        },
    },
    {
        path: 'permissions',
        componentLoader: () => import(
            /* webpackChunkName: "components/pages/VirtualDirectoryPage/PermissionsPage" */
            'components/pages/VirtualDirectoryPage/PermissionsPage'
        ),
    },
    {
        path: 'php',
        componentLoader: () => import(
            /* webpackChunkName: "components/pages/VirtualDirectoryPage/PhpVersionPage" */
            'components/pages/VirtualDirectoryPage/PhpVersionPage'
        ),
        loader: async ({ request: { url }, params: { domainId } }) => {
            const path = new URL(url).searchParams.get('path') || '/';
            const { data } = await apolloClient.query({
                query: VIRTUAL_DIRECTORY_PHP_PAGE,
                variables: { domainId: btoa(`Domain:${domainId}`), path },
            });

            if (!data.domain.subscription.permissions.managePhpVersion ||
            !data.domain.hosting?.virtualDirectory?.isVirtual
            ) {
                throw new Response('Not Found', { status: 404 });
            }
        },
    },
];

export const virtualDirectoryRoute = {
    path: 'virtual-directory',
    componentLoader: () => import(
        /* webpackChunkName: "components/pages/VirtualDirectoryPage" */
        'components/pages/VirtualDirectoryPage'
    ),
    loader: async ({ request: { url }, params: { domainId } }) => {
        const id = btoa(`Domain:${domainId}`);
        const path = new URL(url).searchParams.get('path') || '/';

        const { data } = await apolloClient.query({
            query: VIRTUAL_DIRECTORY_PAGE,
            variables: { domainId: id, path },
        });

        if (!data.domain.hosting) {
            throw new Response('Not Found', { status: 404 });
        }
    },
    handle: {
        helpContext: 'client.domain.hosting.web-directories',
        crumb: () => ({
            title: Locale.getSection('components.routes').lmsg('virtualDirectories'),
        }),
        pageTitle: ({ matchesContext, location: { search } }) => {
            if (!matchesContext.domainRouteData) {
                return null;
            }
            const { domain } = matchesContext.domainRouteData;
            const path = new URLSearchParams(search).get('path') || '/';
            const locale = Locale.getSection('components.routes');
            return locale.lmsg('virtualDirectoryDomain', { path, domainName: `<b>${domain.name}</b>` });
        },
        key: ({ params }) => {
            const path = new URL(window.location.toString()).searchParams.get('path') || '/';
            return `${params.domainId}${path}`;
        },
        secondary: ({ location: { search }, params: { domainId } }) => (
            <VirtualDirectorySidebar
                domainId={domainId}
                path={new URLSearchParams(search).get('path') || '/'}
            />
        ),
    },
    children: [
        {
            index: true,
            loader: ({ request }) => {
                const url = new URL(request.url);
                url.pathname = `${url.pathname}/browse`;
                return redirect(url.toString());
            },
        },
        {
            path: 'browse',
            componentLoader: () => import(
                /* webpackChunkName: "components/pages/VirtualDirectoryPage/Browse" */
                'components/pages/VirtualDirectoryPage/Browse'
            ),
            loader: async ({ request: { url }, params: { domainId } }) => {
                const id = btoa(`Domain:${domainId}`);
                const path = new URL(url).searchParams.get('path') || '/';

                await Promise.all([
                    apolloClient.query({
                        query: VIRTUAL_DIRECTORY_BROWSE_PAGE,
                        variables: { domainId: id, path },
                    }),
                    apolloClient.query({
                        query: VIRTUAL_DIRECTORY_CONTENT_LIST,
                        variables: {
                            domainId: id,
                            path,
                            input: readGraphQlListInputFromStorage({ key: `VirtualDirectoryPage${domainId}` }),
                        },
                    }),
                ]);
            },
            children: [
                ...virtualDirectorySettingRoutes,
                {
                    path: 'create-directory',
                    componentLoader: () => import(
                        /* webpackChunkName: "components/pages/VirtualDirectoryPage/CreateDirectoryPage" */
                        'components/pages/VirtualDirectoryPage/CreateDirectoryPage'
                    ),
                },
            ],
        },
        {
            path: 'protection',
            componentLoader: () => import(
                /* webpackChunkName: "components/pages/VirtualDirectoryPage/Protection" */
                'components/pages/VirtualDirectoryPage/Protection'
            ),
            loader: async ({ request: { url }, params: { domainId } }) => {
                const id = btoa(`Domain:${domainId}`);
                const path = new URL(url).searchParams.get('path') || '/';

                const userListKey = `VirtualDirectoryProtectionUserListPage${domainId}${path}`;

                await Promise.all([
                    apolloClient.query({
                        query: VIRTUAL_DIRECTORY_PROTECTION_PAGE,
                        variables: { domainId: id, path },
                    }),
                    apolloClient.query({
                        query: VIRTUAL_DIRECTORY_PROTECTION_USER_LIST,
                        variables: {
                            domainId: id,
                            path,
                            input: readGraphQlListInputFromStorage({ key: userListKey }),
                        },
                    }),
                ]);

                return { userListKey };
            },
            children: [
                ...virtualDirectorySettingRoutes,
                {
                    ...protectedDirectoryEditRoute,
                    componentLoader: () => import(
                        /* webpackChunkName: "components/pages/VirtualDirectoryPage/ProtectionSettingsPage" */
                        'components/pages/VirtualDirectoryPage/ProtectionSettingsPage'
                    ),
                },
                {
                    ...protectedDirectoryUserAddRoute,
                    path: ':directoryId/add-user',
                },
                protectedDirectoryUserPasswordRoute,
            ],
        },
        {
            path: 'mime-types',
            componentLoader: () => import(
                /* webpackChunkName: "components/pages/VirtualDirectoryPage/MimeTypeList" */
                'components/pages/VirtualDirectoryPage/MimeTypeList'
            ),
            loader: async ({ request: { url }, params: { domainId } }) => {
                const id = btoa(`Domain:${domainId}`);
                const path = new URL(url).searchParams.get('path') || '/';

                const mimeTypeListKey = `VirtualDirectoryMimeTypeList${domainId}${path}`;

                await apolloClient.query({
                    query: VIRTUAL_DIRECTORY_MIME_TYPE_LIST,
                    variables: {
                        domainId: id,
                        path,
                        input: readGraphQlListInputFromStorage({ key: mimeTypeListKey }),
                    },
                });

                return { mimeTypeListKey };
            },
            children: [
                ...virtualDirectorySettingRoutes,
                {
                    path: 'create',
                    componentLoader: () => import(
                        /* webpackChunkName: "components/pages/VirtualDirectoryPage/CreateMimeTypePage" */
                        'components/pages/VirtualDirectoryPage/CreateMimeTypePage'
                    ),
                    loader: async () => {
                        await apolloClient.query({ query: IIS_MANAGER_MIME_CONTENT_TYPES });
                    },
                },
                {
                    path: ':extension/edit',
                    componentLoader: () => import(
                        /* webpackChunkName: "components/pages/VirtualDirectoryPage/EditMimeTypePage" */
                        'components/pages/VirtualDirectoryPage/EditMimeTypePage'
                    ),
                    loader: async ({ request: { url }, params: { domainId, extension } }) => {
                        const [{ data: editPageData }] = await Promise.all([
                            apolloClient.query({
                                query: VIRTUAL_DIRECTORY_EDIT_MIME_TYPE_PAGE,
                                variables: {
                                    domainId: btoa(`Domain:${domainId}`),
                                    path: new URL(url).searchParams.get('path') || '/',
                                    extension,
                                },
                            }),
                            apolloClient.query({ query: IIS_MANAGER_MIME_CONTENT_TYPES }),
                        ]);

                        return {
                            mimeType: editPageData.domain.hosting.virtualDirectory.mimeType,
                        };
                    },
                },
            ],
        },
    ],
};
