// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { ItemList, Item, Icon } from '@plesk/ui-library';
import SidePanel from './SidePanel';
import Link from 'components/common/Link';
import { buildUrl } from 'helpers/buildUrl';
import { Locale } from 'jsw';

import VIRTUAL_DIRECTORY_PAGE from 'queries/VirtualDirectoryPage.graphql';
import { useQuery } from '@apollo/client';
import { getId } from 'helpers/getId';

const Translate = Locale.getTranslate('components.pages.VirtualDirectory');

const VirtualDirectorySidebar = ({ path, domainId }) => {
    const location = useLocation();
    const { data: {
        domain: {
            owner: { id: clientId },
            webScriptingSettings: { aspDotNet, php, errorDocs },
            hosting: { virtualDirectory: { isVirtual, canManagePermissions } },
            subscription: { permissions: { managePhpVersion } },
        },
        capability: { packages: { isASPdotNetInstalled, isPhpInstalled } },
    } } = useQuery(
        VIRTUAL_DIRECTORY_PAGE,
        { variables: { domainId: btoa(`Domain:${domainId}`), path } },
    );

    const uiPointerPrefix = `/plesk/client@${getId(clientId)}/domain@${domainId}/hosting/web-directories`;
    const urlPrefix = location.pathname;

    const buttonProperties = isVirtual;
    const buttonPermissions = canManagePermissions;
    const buttonAspDotNet = aspDotNet && isASPdotNetInstalled;
    const buttonPhp = isVirtual && isPhpInstalled && php && managePhpVersion;

    const buttonErrorDocs = isVirtual && errorDocs;

    return (
        <>
            {(buttonProperties || buttonPermissions || buttonAspDotNet || buttonPhp) && (
                <SidePanel id="panel-virtualDirectory">
                    <ItemList>
                        {buttonProperties && (
                            <Item
                                id="buttonProperties"
                                icon={<Icon name="pencil" size="16" />}
                                component={Link}
                                to={buildUrl(`${uiPointerPrefix}/properties`, { wdir: path })}
                                tooltip={<Translate content="buttonPropertiesHint" />}
                            >
                                <Translate content="buttonProperties" />
                            </Item>
                        )}
                        {buttonPermissions && (
                            <Item
                                id="buttonPermissions"
                                icon={<Icon name="lock-closed-check" size="16" />}
                                component={Link}
                                to={buildUrl(`${urlPrefix}/permissions`, { path })}
                                preventScrollReset
                                tooltip={<Translate content="buttonPermissionsHint" />}
                            >
                                <Translate content="buttonPermissions" />
                            </Item>
                        )}
                        {buttonAspDotNet && (
                            <Item
                                id="buttonAspDotNet"
                                icon={<Icon name="microsoft" size="16" />}
                                component={Link}
                                to={buildUrl(`${urlPrefix}/asp-dot-net`, { path })}
                                preventScrollReset
                                tooltip={<Translate content="buttonAspDotNetHint" />}
                            >
                                <Translate content="buttonAspDotNet" />
                            </Item>
                        )}
                        {buttonPhp && (
                            <Item
                                id="buttonPhp"
                                icon={<Icon name="php" size="16" />}
                                component={Link}
                                to={buildUrl(`${urlPrefix}/php`, { path })}
                                preventScrollReset
                                tooltip={<Translate content="buttonPhpHint" />}
                            >
                                <Translate content="buttonPhp" />
                            </Item>
                        )}
                    </ItemList>
                </SidePanel>
            )}
            {buttonErrorDocs && (
                <SidePanel>
                    <ItemList>
                        <Item
                            id="buttonErrorDocs"
                            icon={<Icon name="site-page" size="16" />}
                            component={Link}
                            to={buildUrl(`${uiPointerPrefix}/error-doc@`, { wdir: path })}
                            tooltip={<Translate content="buttonErrorDocsHint" />}
                        >
                            <Translate content="buttonErrorDocs" />
                        </Item>
                    </ItemList>
                </SidePanel>
            )}
        </>
    );
};

VirtualDirectorySidebar.propTypes = {
    path: PropTypes.string.isRequired,
    domainId: PropTypes.string.isRequired,
};

export default VirtualDirectorySidebar;
