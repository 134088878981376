// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { useLoaderData, useParams, useLocation, useRevalidator, useMatches } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { redirect } from 'jsw';

import LICENSE from 'queries/License.graphql';

const LoadableComponent = () => {
    const matches = useMatches();
    const location = useLocation();
    const revalidator = useRevalidator();
    const { Component, ...data } = useLoaderData();
    const params = useParams();
    const { data: licenseData } = useQuery(LICENSE);

    if (licenseData?.mode?.isLicenseLocked) {
        const isAllowedWithoutLicense = matches.some(({ handle }) => handle?.isAllowedWithoutLicense);
        if (!isAllowedWithoutLicense) {
            return redirect('/cp/license/');
        }
    }

    if (location.state?.reload) {
        delete location.state.reload;
        revalidator.revalidate();
    }

    return <Component {...data} params={params} />;
};

const componentLoaderTransformer = route => {
    if (!route.componentLoader) {
        return route;
    }

    const { element, componentLoader, loader, ...other } = route;

    if (element) {
        throw new Error('Unable to use options "componentLoader" and "element" at the same time', { cause: { route } });
    }

    return {
        ...other,
        element: <LoadableComponent />,
        loader: async ({ request, params }) => {
            const [Component, data] = await Promise.all([
                componentLoader().then(module => module.default || module),
                loader ? loader({ request, params }) : Promise.resolve(),
            ]);

            if (data instanceof Response) {
                return data;
            }

            return { ...data, Component };
        },
    };
};

const isExistTransformer = route => {
    if (!route.isExist) {
        return route;
    }

    const { isExist, loader, ...other } = route;

    return {
        ...other,
        loader: async ({ request, params }) => {
            if (!(await isExist({ request, params }))) {
                throw new Response('Not Found', { status: 404 });
            }
            if (loader) {
                return loader({ request, params });
            }
        },
    };
};

const shouldRevalidateTransformer = route => {
    if (!route.loader) {
        return route;
    }

    const { shouldRevalidate, ...other } = route;

    return {
        ...other,
        shouldRevalidate: ({ defaultShouldRevalidate, ...args }) => {
            // Skip revalidation due to open/modify/close modals
            defaultShouldRevalidate &&= [...args.currentUrl.searchParams.keys(), ...args.nextUrl.searchParams.keys()]
                .every(param => !param.match(/^(modals\[.+]|module)$/));

            if (shouldRevalidate) {
                defaultShouldRevalidate = shouldRevalidate({ ...args, defaultShouldRevalidate });
            }

            return defaultShouldRevalidate;
        },
    };
};

const childrenTransformer = route => {
    if (!Array.isArray(route.children)) {
        return route;
    }

    const { children, ...other } = route;

    return {
        ...other,
        children: routesTransformer(children),
    };
};

const pathTransformer = route => {
    if (!Array.isArray(route.path)) {
        return route;
    }

    const { path, ...other } = route;

    return path.map(path => ({
        ...other,
        path,
    }));
};

const routeTransformer = route => [
    componentLoaderTransformer,
    isExistTransformer,
    shouldRevalidateTransformer,
    childrenTransformer,
    pathTransformer,
].reduce((route, transformer) => transformer(route), route);

const routesTransformer = routes => routes.map(routeTransformer).flat();

export {
    componentLoaderTransformer,
    isExistTransformer,
    shouldRevalidateTransformer,
    childrenTransformer,
    pathTransformer,
    routeTransformer,
};
export default routesTransformer;
